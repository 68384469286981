import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Row, Col, Button } from "antd";
import { examteamsServ } from "../../Services/examteamsService";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
const ExamTeamsCLOs = () => {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [examInfo, setExamInfo] = useState({}); // State to store exam information

  useEffect(() => {
    examteamsServ
      .getExamteams(id)
      .then((data) => {
        setExamInfo({
          courseID: data.courseID,
          courseName: data.courseName,
          courseGroup: data.courseGroup,
          examTeams: data.examTeams,
          numberOfStudent: data.numberOfStudent,
          examDate: data.examDate,
          examTime: data.examTime,
          examRoom: data.examRoom,
          examType: data.examType,
          lecturer: data.lecturer,
        });
      })
      .catch((error) => {
        //message.error("Failed to fetch exam data: " + error.message);
        console.error(error);
      });
  }, [id]);
  // const downloadExcel = () => {
  //   const workbook = XLSX.utils.book_new();
  //   const ws = XLSX.utils.json_to_sheet([]);
  
  //   // Convert the exam information into a worksheet format
  //   const examInfoData = [
  //     { A: 'Exam Information', B: null },
  //     { A: 'Course ID', B: examInfo.courseID },
  //     { A: 'Course Name', B: examInfo.courseName },
  //     { A: 'Course Group', B: examInfo.courseGroup },
  //     { A: 'Exam Teams', B: examInfo.examTeams },
  //     { A: 'Number of Students', B: examInfo.numberOfStudent },
  //     { A: 'Exam Date', B: examInfo.examDate },
  //     { A: 'Exam Time', B: examInfo.examTime },
  //     { A: 'Exam Room', B: examInfo.examRoom },
  //     { A: 'Exam Type', B: examInfo.examType },
  //     { A: 'Lecturer', B: examInfo.lecturer },
  //     { A: null, B: null }, // Empty row for spacing
  //   ];
  //   XLSX.utils.sheet_add_json(ws, examInfoData, { skipHeader: true, origin: 'A1' });
  
  //   // Find the next empty row to start appending the CLOs Results
  //   let nextEmptyRow = examInfoData.length + 2;
  
  //   // Append CLOs Results to the same worksheet
  //   XLSX.utils.sheet_add_json(ws, tableData, {
  //     header: columns.map(col => col.title),
  //     skipHeader: false,
  //     origin: `A${nextEmptyRow}` 
  //   });
  
  //   XLSX.utils.book_append_sheet(workbook, ws, 'ExamReport');
  
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'ExamReport.xlsx');
  // };
  
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    // Convert the exam information into a worksheet format
    const examInfoData = [
      { A: 'Exam Information', B: null },
      { A: 'Course ID', B: examInfo.courseID },
      { A: 'Course Name', B: examInfo.courseName },
      { A: 'Course Group', B: examInfo.courseGroup },
      { A: 'Exam Teams', B: examInfo.examTeams },
      { A: 'Number of Students', B: examInfo.numberOfStudent },
      { A: 'Exam Date', B: examInfo.examDate },
      { A: 'Exam Time', B: examInfo.examTime },
      { A: 'Exam Room', B: examInfo.examRoom },
      { A: 'Exam Type', B: examInfo.examType },
      { A: 'Lecturer', B: examInfo.lecturer },
      { A: null, B: null }, // Empty row for spacing
    ];
    XLSX.utils.sheet_add_json(ws, examInfoData, { skipHeader: true, origin: 'A1' });

    // Find the next empty row to start appending the CLOs Results
    let nextEmptyRow = examInfoData.length + 2;

    // Append CLOs Results to the same worksheet, using 'question' as the first column, exclude 'key'
    const formattedData = tableData.map(({ key, question, ...rest }) => ({
        Question: question, // Assign question to be the first column
        ...rest
    }));

    XLSX.utils.sheet_add_json(ws, formattedData, {
      header: ['Question', 'CLO1', 'CLO2', 'CLO3'], // define headers explicitly including 'Question' and excluding 'key'
      skipHeader: false,
      origin: `A${nextEmptyRow}`
    });

    XLSX.utils.book_append_sheet(workbook, ws, 'ExamReport');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'ExamReport.xlsx');
};

  
  // const s2ab = (s) => {
  //   const buffer = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buffer);
  //   for (let i = 0; i < s.length; i++) {
  //     view[i] = s.charCodeAt(i) & 0xFF;
  //   }
  //   return buffer;
  // };
  
  useEffect(() => {
    examteamsServ
      .getExamteams(id)
      .then((data) => {
        const totalStudents = parseInt(data.numberOfStudent, 10);
        const dataSource = calculateCLOsTable(
          data.examScore,
          data.examStructure,
          totalStudents
        );
        setTableData(dataSource);
        setColumns(generateColumns(data.examStructure));
      })
      .catch((error) => {
        //message.error("Failed to fetch exam data: " + error.message);
        console.error(error);
      });
  }, [id]);
  
  const calculateCLOsTable = (examScores, examStructure, totalStudents) => {
    const questionCLOsMap = {};
    const cloTotals = {}; // Object to hold total pass count for each CLO
    const cloQuestionCounts = {}; // Object to hold the number of questions for each CLO
  
    // Initialize the mapping for each question and CLO
    examStructure.tieuchi.forEach((criterion) => {
      questionCLOsMap[criterion.id] = {
        key: criterion.id,
        question: criterion.id,
      };
  
      criterion.note.forEach((clo) => {
        questionCLOsMap[criterion.id][clo] = 0; // Initialize pass count
        cloTotals[clo] = 0; // Initialize CLO totals
        cloQuestionCounts[clo] = (cloQuestionCounts[clo] || 0) + 1; // Count the questions for each CLO
      });
    });
  
    // Aggregate scores and pass counts for each question and CLO
    examScores.forEach(({ student }) => {
      student.scores.forEach(({ title, score }) => {
        const criterion = examStructure.tieuchi.find((c) => c.id === title);
        if (criterion) {
          criterion.note.forEach((clo) => {
            if (["A", "G", "E"].includes(score) || (!isNaN(score) && parseInt(score, 10) >= criterion.maxScore / 2)) {
              questionCLOsMap[title][clo] += 1; // Increase pass count for the question
              cloTotals[clo] += 1; // Increase total pass count for the CLO
            }
          });
        }
      });
    });
  
    // Convert the question CLOs map to an array format suitable for the table
    const tableData = Object.values(questionCLOsMap);
  
    // Add a row for averages at the end of the tableData array
    const averageRow = { key: 'average', question: 'Average for each CLO' };
    Object.keys(cloTotals).forEach(clo => {
      // Calculate average pass count for each CLO and round it to the nearest whole number
      const average = cloTotals[clo] / cloQuestionCounts[clo];
      averageRow[clo] = Math.round(average); // Round the average
    });
    tableData.push(averageRow);
  
    // Calculate percentages based on the averages and add a row for percentages
    const percentageRow = { key: 'percentage', question: '% Pass CLO' };
    Object.keys(averageRow).forEach(clo => {
      if (clo !== 'key' && clo !== 'question') {
        const percentage = (averageRow[clo] / totalStudents) * 100;
        percentageRow[clo] = `${percentage.toFixed(2)}%`; // Calculate percentage based on the average
      }
    });
    tableData.push(percentageRow);
  
    return tableData;
  };

  const generateColumns = (examStructure, tableData) => {
    // Sắp xếp các CLO theo thứ tự từ CLO1 đến CLO5
    const sortedCLOs = [...Array(5)].map((_, i) => `CLO${i + 1}`);
  
    // Determine which CLOs have scoring data
    const closWithData = sortedCLOs.filter(clo =>
      tableData.some(row => row[clo] && parseFloat(row[clo]) > 0)
    );
    
  
    // Tạo cột cho mỗi CLO có dữ liệu
    const cloColumns = closWithData.flatMap(clo => [
      {
        title: clo,
        dataIndex: clo,
        key: clo,
        render: text => text !== undefined ? `${text}` : "-",
      }
      // Removed the Pass CLO columns. Uncomment and include them if needed.
      // {
      //   title: `Pass ${clo}`,
      //   dataIndex: `pass-${clo}`,
      //   key: `pass-${clo}`,
      //   render: text => text !== undefined ? text : "-",
      // },
    ]);
  
    // Thêm cột 'Question' vào đầu danh sách cột
    return [
      { title: "Question", dataIndex: "question", key: "question" },
      ...cloColumns,
    ];
  };
  
  // You need to call generateColumns with the tableData when you get the exam data
  useEffect(() => {
    examteamsServ
      .getExamteams(id)
      .then((data) => {
        const totalStudents = parseInt(data.numberOfStudent, 10);
        const dataSource = calculateCLOsTable(
          data.examScore,
          data.examStructure,
          totalStudents
        );
        setTableData(dataSource);
        // Pass the tableData to generateColumns to filter CLOs with data
        setColumns(generateColumns(data.examStructure, dataSource));
      })
      .catch((error) => {
        // message.error("Failed to fetch exam data: " + error.message);
        // console.error(error);
      });
  }, [id, tableData]); // Include tableData in the dependency array to update columns when tableData changes
  
  return (
    <div>
      <h1 style={{ fontSize: "2em" }}>Exam Information</h1>
      <Button
        type="primary"
        onClick={downloadExcel}
        style={{ marginBottom: "20px" }}
      >
        Download as Excel
      </Button>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <strong>Course ID:</strong> {examInfo.courseID}
          </p>
          <p>
            <strong>Course Name:</strong> {examInfo.courseName}
          </p>
          <p>
            <strong>Course Group:</strong> {examInfo.courseGroup}
          </p>
          <p>
            <strong>Exam Teams:</strong> {examInfo.examTeams}
          </p>
          <p>
            <strong>Number of Students:</strong> {examInfo.numberOfStudent}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <strong>Exam Date:</strong> {examInfo.examDate}
          </p>
          <p>
            <strong>Exam Time:</strong> {examInfo.examTime}
          </p>
          <p>
            <strong>Exam Room:</strong> {examInfo.examRoom}
          </p>
          <p>
            <strong>Exam Type:</strong> {examInfo.examType}
          </p>
          <p>
            <strong>Lecturer:</strong> {examInfo.lecturer}
          </p>
        </Col>
      </Row>
      <h2>CLOs Results</h2>
      <Table dataSource={tableData} columns={columns} pagination={false} />
    </div>
  );
};

export default ExamTeamsCLOs;

  // ... rest of your component
  
  // const generateColumns = (examStructure) => {
  //   // Sắp xếp các CLO theo thứ tự từ CLO1 đến CLO5
  //   const sortedCLOs = [...Array(5)].map((_, i) => `CLO${i + 1}`);

  //   // Tạo cột cho mỗi CLO và cột Pass tương ứng
  //   const cloColumns = sortedCLOs.flatMap((clo) => [
  //     {
  //       title: clo,
  //       dataIndex: clo,
  //       key: clo,
  //       render: (text) => (text !== undefined ? `${text}` : "-"),
  //     },
  //     // {
  //     //   title: `Pass ${clo}`,
  //     //   dataIndex: `pass-${clo}`,
  //     //   key: `pass-${clo}`,
  //     //   render: (text) => (text !== undefined ? text : "-"),
  //     // },
  //   ]);

  //   // Thêm cột 'Question' vào đầu danh sách cột
  //   return [
  //     { title: "Question", dataIndex: "question", key: "question" },
  //     ...cloColumns,
  //   ];
  // };
