import { https } from "./configURL";

export const classServ = {

  editClass: (id, data) => {
    console.log(`Editing class with ID: ${id}`, data);
    return https
      .put(`/api/class/${id}`, data)
      .then((response) => {
        console.log("Edit response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Edit error:", error);
        throw error;
      });
  },

  deleteClass: (id) => {
    console.log(`Deleting class with ID: ${id}`);
    return https
      .delete(`/api/class/${id}`)
      .then((response) => {
        console.log("Delete response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Delete error:", error);
        throw error;
      });
  },

  addClass: (data) => {
    console.log("Adding class:", data);
    return https
      .post("/api/class", data.class)
      .then((response) => {
        console.log("Add response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Add error:", error);
        throw error;
      });
  },


  getClass: (id) => {
    console.log(`Getting Class with ID: ${id}`);
    return https
      .get(`/api/class/${id}`)
      .then((response) => {
        console.log("Get response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Get error:", error);
        throw error;
      });
  },

  editExamTeams: (classId, teamId, data) => {
    console.log(`Editing exam team with ID: ${teamId} in class: ${classId}`, data);
    return https
      .put(`/api/class/${classId}/examTeams/${teamId}`, data)
      .then((response) => {
        console.log("Edit response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Edit error:", error);
        throw error;
      });
  },

  getExamTeams: (classId, teamId) => {
    console.log(`Getting exam team with ID: ${teamId} in class: ${classId}`);
    return https
      .get(`/api/class/${classId}/examTeams/${teamId}`)
      .then((response) => {
        console.log("Get response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Get error:", error);
        throw error;
      });
  },

  getAllExamTeams: (classId) => {
    console.log(`Getting all exam teams in class: ${classId}`);
    return https
      .get(`/api/class/${classId}/examTeams`)
      .then((response) => {
        console.log("Get all exam teams response:", response.data);
        if (response.data && Array.isArray(response.data)) {
          return response.data;
        } else {
          console.error("Unexpected response structure", response.data);
          throw new Error(
            "Unexpected response structure: " +
              JSON.stringify(response.data, null, 2)
          );
        }
      })
      .catch((error) => {
        console.error("API call failed", error.response || error.message || error);
        throw error;
      });
  },

  deleteExamTeam: (classId, teamId) => {
    console.log(`Deleting exam team with ID: ${teamId} in class: ${classId}`);
    return https
      .delete(`/api/class/${classId}/examTeams/${teamId}`)
      .then((response) => {
        console.log("Delete response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Delete error:", error);
        throw error;
      });
  },

  importExamTeam: (classId, data) => {
    console.log(`Adding exam team to class: ${classId}`, data);
    return https
      .post(`/api/class/${classId}/examTeams`, data)
      .then((response) => {
        console.log("Add response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Add error:", error);
        throw error;
      });
  },

  importClass: (data) =>{
    return https
    .post("/api/class/", data) // Make sure the endpoint matches your API
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
  },

  getDataClass: () => {
    console.log("Getting all Class");
    return https
      .get("/api/class")
      .then((response) => {
        console.log("Get all Classes response:", response.data);
        if (response.data && Array.isArray(response.data)) {
          return response.data;
        } else {
          console.error("Unexpected response structure", response.data);
          throw new Error(
            "Unexpected response structure: " +
              JSON.stringify(response.data, null, 2)
          );
        }
      })
      .catch((error) => {
        console.error(
          "API call failed",
          error.response || error.message || error
        );
        throw error;
      });
  },

  importExamTeamScore: (classId, teamId, data) => {
    console.log(`Importing exam team score with ID: ${teamId} in class: ${classId}`, data);
    return https
      .put(`/api/class/${classId}/examTeams/${teamId}`, data)
      .then((response) => {
        console.log("Import Exam Team Score response:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Import Exam Team Score error:", error);
        throw error;
      });
  },

  importStudents: async (classId, newStudents) => {
    console.log(`Importing students to class: ${classId}`, newStudents);

    // Fetch the current class data
    const currentClass = await classServ.getClass(classId);

    // Ensure the students array is correctly accessed and is an array
    const students = Array.isArray(currentClass.students) ? currentClass.students : [];

    // Update the students array with the new list of students
    const updatedClassData = {
        ...currentClass.class, // Spread the existing class data
        students: [...students, ...newStudents], // Update the students array
    };

    // Construct the updated class object with the correct structure
    const updatedClass = {
        class: updatedClassData,
        id: currentClass.id, // Preserve the class ID
    };

    // Send the updated class data to the backend
    return https
        .put(`/api/class/${classId}`, updatedClass)
        .then((response) => {
            console.log("Import Students response:", response.data);
            return response.data;
        })
        .catch((error) => {
            console.error("Import Students error:", error);
            throw error;
        });
},



};
