import {React, useEffect, useState} from "react";
import { message, Popconfirm, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { classServ } from "../../Services/classService";
import { setDataListClass } from "../../Redux/actions/actionClass";
import * as XLSX from "xlsx";
import { ExportOutlined } from "@ant-design/icons";

export default function ClassTable({ semester = '', schoolyear = '' }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classList = useSelector(
    (state) => state.classReducer.dataListClass || []
  );

   // State to hold the filtered list
 const [filteredClassList, setFilteredClassList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    console.log("Semester:", semester, "School Year:", schoolyear);
    const filtered = classList.filter((item) => {
      const matchesSemester = semester
        ? item.semester === semester
        : true;
      const matchesSchoolYear = schoolyear
        ? item.schoolyear === schoolyear
        : true;
      return matchesSemester && matchesSchoolYear;
    });

    setFilteredClassList(filtered);

    if (filtered.length > 0) {
      const selectedClass = filtered[0].id;
      setStudentList(selectedClass.students || []);
    }
  }, [classList, semester, schoolyear]);


  const handleExport = (classInfo) => {
    try {
      if (!classInfo) {
        throw new Error("Class information is undefined.");
      }
  
      const { semester, schoolyear, courseName, courseID, courseGroup, lecturer, students } = classInfo;
  
      const data = [
        ["Semester:", semester, "School Year:", schoolyear],
        ["Course Name", courseName],
        ["Course ID/Course Group", `${courseID}/${courseGroup}`],
        ["Lecturer", lecturer],
        ["Student ID", "First Name", "Last Name"],
      ];
  
      students.forEach(student => {
        data.push([student.studentID, student.studentFirstName, student.studentLastName]);
      });
  
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Student List");
  
      const buffer = XLSX.write(workbook, {
        type: "buffer",
        bookType: "xlsx",
      });
  
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "student_list.xlsx";
  
      link.click();
  
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting data:", error.message);
      message.error("Failed to export data. Please try again.");
    }
  };
  
  

  // Function to handle the deletion of an exam team
  const handleDelete = (id) => {
    classServ
      .deleteClass(id)
      .then(() => {
        message.success("Class deleted successfully");
        // Re-fetch the updated list of exam teams
        dispatch(setDataListClass());
      })
      .catch((err) => {
        message.error("Failed to delete class");
        console.error(err);
      });
  };


 const dataSource = filteredClassList.map((item) => ({
  id: item.id,
  name: item.name,
  courseID: item.courseID,
  courseName: item.courseName,
  courseGroup: item.courseGroup,
  numberOfStudent: item.numberOfStudent,
  lecturer: item.lecturer,
  students: item.students || [], }));

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Class Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Course ID",
      dataIndex: "courseID",
      key: "courseID",
    },
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "Course Group",
      dataIndex: "courseGroup",
      key: "courseGroup",
    },
    {
      title: "No of Student",
      dataIndex: "numberOfStudent",
      key: "numberOfStudent",
    },
    {
      title: "Lecturer",
      dataIndex: "lecturer",
      key: "lecturer",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="space-x-2">
          <Popconfirm
            title="Are you sure to delete this class?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <span className="hover:cursor-pointer text-red-500">Delete</span>
          </Popconfirm>
          <br />
          <span
            className="hover:cursor-pointer text-blue-500"
            onClick={() => navigate(`/admin/class/edit/${record.id}`)}
          >
            Edit
          </span>
          <br />
        
        </div>
      ),
    },
    {
      title: "Detail",
      key: "detail",  
      render: (_, record) => (
        <div className="space-x-2">
         <button
 className="hover:cursor-pointer text-green-500"
 onClick={() => navigate(`/admin/examteams`, {
    state: {
      courseID: record.courseID,
      lecturer: record.lecturer,
    }
 })}
>
 View Exam Team
</button>
        </div>
      ),
    },
    {
      title: "Export",
      key: "export",
      render: (_, record) => (
        <ExportOutlined
          className="hover:cursor-pointer text-green-500"
          onClick={() => handleExport(record)}
        />
      ),
    },
    
  ];


  return <Table columns={columns} dataSource={dataSource} />;
}
