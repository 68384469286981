import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Upload, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import {
  importExamTeamsScores,
  setDataListExamteams,
} from "../../Redux/actions/actionExamteams";
import {
  setDataListClass
} from "../../Redux/actions/actionClass";
import ExamTeamsScoreTable from "./ExamTeamsScoreTable";
import { saveAs } from 'file-saver'; // For saving files


export default function ExamTeamsScore() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileData, setFileData] = useState([]);

  const examTeamsScores = useSelector(
    (state) =>
      state.examteamsReducer.dataListExamteams?.find((team) => team.id === id)
        ?.examScore || []
  );


  const examTeamsStructure = useSelector(
    (state) =>
      state.examteamsReducer.dataListExamteams?.find((team) => team.id === id)
        ?.examStructure || []
  );

  const examTeamData = useSelector(
    (state) =>
    state.examteamsReducer.dataListExamteams?.find((team) => team.id === id));

  const classData = useSelector(state => state.classReducer.dataListClass);

  const exportToExcel = () => {
    // Check if examTeamData is defined
    if (!examTeamData) {
       message.error("Exam team data is not available.");
       return;
    }

    // Now, use the courseID from the specific exam team to find the matching class
    const matchingClass = classData?.find(classItem =>
       classItem.courseID === examTeamData.courseID
    );
    if (!matchingClass) {
       message.error("No matching class found.");
       return;
    }

    // Extract all unique tieuchi IDs from the examTeamsStructure
    const allTieuChiIds = examTeamsStructure.tieuchi.map((tieuchi) => tieuchi.id).filter((id, index, self) => self.indexOf(id) === index);

    // Prepare the headers for the Excel file
    const headers = [
       "No",
       "Student ID",
       "Student First Name",
       "Student Last Name",
       ...allTieuChiIds.map(id => `${id}`), // Dynamically create headers for each tieuchi ID
       "Note"
    ];

    // Prepare the data for export, including headers as the first row
    const dataForExport = [
       headers,  // Add headers as the first row
       ...matchingClass.students.map((item,index) => {
           const studentData = {
            "No": index +1,
             "Student ID": item.studentID || "",
             "Student First Name": item.studentFirstName || "",
             "Student Last Name": item.studentLastName || "",
           };

           // For each tieuchi ID, add the corresponding maxScore to studentData
           allTieuChiIds.forEach((tieuchiId) => {
             const tieuchi = item.examStructure?.tieuchi?.find((tieuchi) => tieuchi.id === tieuchiId);
             studentData[`${tieuchiId}`] = tieuchi ? tieuchi.maxScore : "";
           });

           // Add the "Note" field
           studentData["Note"] = item.note || "";

           return studentData;
       })
    ];

    // Convert the data to a worksheet
    const ws = XLSX.utils.json_to_sheet(dataForExport, { skipHeader: true });

    // Create a new workbook and append the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Student Scores");

    // Write the workbook to a binary string and save it as an Excel file
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff;

    saveAs(new Blob([buf], { type: "application/octet-stream" }), "student_scores.xlsx");
};

   
   
  
   useEffect(() => {
    dispatch(setDataListClass());
 }, [dispatch]);


  useEffect(() => {
    dispatch(setDataListExamteams(id));
  }, [dispatch, id]);


  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: null // Set default value for empty cells to null
        });
  
        const headers = jsonData.shift(); // The first row should be headers
        // Find the index for the 'Note' column, assuming 'Note' is in the headers
        const noteIndex = headers.indexOf('Note');
  
        const formattedData = jsonData.map((row) => {
          if (!row[1]) return null; // Skip the row if 'Student ID' is not present
  
          const scores = headers.slice(4, noteIndex).map((title, index) => ({
            title: title.toString(),
            score: row[index + 4] !== null ? row[index + 4].toString() : ""
          }));
  
          // Get the note from the cell at the noteIndex, if it exists
          const note = noteIndex > -1 && row[noteIndex] !== null ? row[noteIndex].toString() : "";
  
          return {
            student: {
              studentID: row[1],
              studentFirstName: row[2],
              studentLastName: row[3],
              scores: scores,
              note:note,
            }
          };
        }).filter(Boolean); // Filter out any null entries if rows were skipped
  
        setFileData(formattedData);
        message.success("Data loaded from file successfully.");
      } catch (error) {
        console.error("Error reading the Excel file:", error);
        message.error("There was an issue processing the Excel file.");
      }
    };
  
    reader.onerror = () => {
      console.error("FileReader error");
      message.error("Failed to read the file");
    };
  
    reader.readAsBinaryString(file);
    return false;
  };
  
  
  const beforeUpload = (file) => {
    const isExcel =
      file.type.includes("excel") || file.type.includes("spreadsheetml");
    if (!isExcel) {
      message.error(`${file.name} is not an excel file.`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const saveDataToAPI = async () => {
    try {
      const response = await dispatch(importExamTeamsScores(id, fileData));
      message.success("All scores have been successfully uploaded.");
      dispatch(setDataListExamteams(id));
    } catch (error) {
      console.error("Upload failed:", error);
      message.error("Failed to upload scores. Please try again.");
    }
  };

  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl">Exam Teams Scores</h1>
      <h3>Lưu ý: Vui lòng nhập Exam Structure trước khi import điểm</h3>
      <Button icon={<UploadOutlined />} onClick={showModal}>
        Import from Excel
      </Button>
      <Button onClick={exportToExcel}>Download Exam Score File to Upload</Button>

      <br />
      <Button type="danger" onClick={saveDataToAPI}>Save Scores</Button>


      <Modal
        title="Import Excel File"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Upload
          beforeUpload={beforeUpload}
          customRequest={({ file }) => handleFileUpload(file)}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <div>
          <a href={`/assets/Examscore.xlsx`} download="Examscore.xlsx">
            Download Sample File
          </a>
        </div>

      
      </Modal>
      
      <ExamTeamsScoreTable data={examTeamsScores} onDataChange={setFileData} />
    </div>
  );
}
