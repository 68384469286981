import React, { useState, useEffect } from "react";
import {Button, Select, Modal, message, Upload} from "antd";
import * as XLSX from "xlsx";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import ClassTable from "./ClassTable";
import { importClass, setDataListClass } from "../../Redux/actions/actionClass";



const { Option } = Select;


export default function Class() {
    const dispatch = useDispatch();
    const [semester, setSemester] = useState("");
    const [schoolyear, setSchoolYear] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        dispatch(setDataListClass());
    }, [dispatch]);


    const handleFileUpload = (file) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
            try {
                const workbook = XLSX.read(e.target.result, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const excelData = XLSX.utils.sheet_to_json(worksheet);

                const formattedData = excelData.map(item => ({
                    id: item["ID"],
                  
                    name: `${item["Course Name"].replace(/\s+/g, '')}_S${item["Semester"]}_${item["School Year"]}_G${item["Course Group"]}`, // Remove spaces from Course Name

                      courseID: item["Course ID"],
                      courseName: item["Course Name"],
                      courseGroup: item["Course Group"],
                      numberOfStudent: item["Number of Student"],
                      lecturer: item["Lecturer"],
                      semester: item["Semester"],
                      schoolyear: item["School Year"],
                      students: [
                        {
                          studentID: item["Student ID"],
                          studentFirstName: item["Student First Name"],
                          studentLastName: item["Student Last Name"]
                        }
                      ]

                  }));

               
                for (const classData of formattedData) {
                    console.log("Processing class data:", classData); // Debugging line
                    await dispatch(importClass(classData));
                }

                dispatch(setDataListClass());

            } catch (error) {
                console.error("Error reading the Excel file: ", error);
                message.error("There was an issue processing the Excel file.");
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading the Excel file: ", error);
            message.error("There was an issue reading the Excel file.");
        };

        reader.readAsBinaryString(file);
        return false; // Prevent default upload behavior
    };

    const beforeUpload = (file) => {
        const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel";
        if (!isExcel) {
            message.error(`${file.name} is not an excel file.`);
        }
        return isExcel || Upload.LIST_IGNORE;
    };

    const handleSemesterChange = (value) => {
        setSemester(value);
    };

    const handleSchoolYearChange = (value) => {
        setSchoolYear(value);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


  return (
    <div className="container mx-auto">
        <h1 className="text-2xl">Class</h1>
       
        <Button icon={<UploadOutlined />} onClick={showModal}>Import from Excel</Button>
            <Modal title="Import Excel File" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Upload
                    beforeUpload={beforeUpload}
                    customRequest={({ file }) => handleFileUpload(file)}
                    showUploadList={false}
                >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
                <div>
                    <a href={`/assets/ExampleClass.xlsx`} download="ExampleClass.xlsx">Download Sample File</a>
                </div>
            </Modal>

        <div className="flex justify-start items-center my-4">
            <span style={{ marginRight: 8 }}>Semester:</span>
            <Select
    placeholder="Select semester"
    style={{ width: 150 }}
    value={semester}
    onChange={handleSemesterChange} // Ensure this handler is set
>
    <Option value="1">1</Option>
    <Option value="2">2</Option>
</Select>
<Select
    placeholder="Select school year"
    style={{ width: 150 }}
    value={schoolyear}
    onChange={handleSchoolYearChange} // Ensure this handler is set
>
    <Option value="22-23">22-23</Option>
    <Option value="23-24">23-24</Option>
</Select>

        </div>

        <ClassTable semester={semester} schoolyear={schoolyear} />
    </div>
);
}
