// reducerExamteam
import {
    SET_DATA_CLASS,
    SET_CLASS,
  } from "../constant/constantClass";
  
  const initialState = {
    dataListClass: [],
    class: null,
  };
  
  export const classReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_CLASS:
        return { ...state, class: action.payload };
      case SET_DATA_CLASS:
      return { ...state, dataListClass: action.payload };
        case "UPDATE_CLASS":
        return { ...state, dataListClass: action.payload };
      // handle other actions
      default:
        return state;
    }
  };
  