import React, {  useState } from 'react';
import { Table, Form, Input, Button } from 'antd';

const ExamTeamsScoreTable = ({ data, id, onDataChange }) => {
 const [editingStudent, setEditingStudent] = useState(null);
 const [form] = Form.useForm();
 const [expandedRowKey, setExpandedRowKey] = useState(null);

 const showEditForm = (record) => {
    setEditingStudent(record);
    const scores = record.student.scores.reduce((acc, curr) => {
      acc[curr.title] = curr.score;
      return acc;
    }, {});
    form.setFieldsValue({
      ...scores,
      note: record.student.note,
    });
 };

 const handleSave = () => {
    form.validateFields().then((values) => {
      const updatedScores = editingStudent.student.scores.map((score) => ({
        ...score,
        score: values[score.title],
      }));

      const updatedStudentData = {
        ...editingStudent,
        student: {
          ...editingStudent.student,
          scores: updatedScores,
          note: values.note,
        },
      };

      onDataChange(
        data.map((item) =>
          item.student.studentID === updatedStudentData.student.studentID
            ? updatedStudentData
            : item
        )
      );
    }).catch((info) => {
      console.log('Validate Failed:', info);
    });
 };

 const generateColumns = () => {
    const baseColumns = [
      { title: 'STT', dataIndex: 'stt', key: 'stt', render: (_, __, index) => index + 1 },
      { title: 'Student ID', dataIndex: ['student', 'studentID'], key: 'studentID' },
      { title: 'Student First Name', dataIndex: ['student', 'studentFirstName'], key: 'firstName' },
      { title: 'Student Last Name', dataIndex: ['student', 'studentLastName'], key: 'lastName' },
    ];

    const scoreTitles = data.reduce((titles, item) => {
      if (Array.isArray(item.student.scores)) {
        item.student.scores.forEach((score) => {
          if (score && !titles.includes(score.title)) {
            titles.push(score.title);
          }
        });
      }
      return titles;
    }, []);

    const scoreColumns = scoreTitles.map((title) => ({
      title,
      dataIndex: ['student', 'scores'],
      key: title,
      render: (scores) => {
        const scoreObject = scores?.find((s) => s.title === title);
        return scoreObject ? scoreObject.score : '-';
      },
    }));

    const noteColumn = {
      title: 'Note',
      dataIndex: ['student', 'note'],
      key: 'note',
    };

    return [...baseColumns, ...scoreColumns, noteColumn];
 };

 const toggleRow = (record) => {
    if (expandedRowKey === record.key) {
      setExpandedRowKey(null);
    } else {
      setExpandedRowKey(record.key);
      showEditForm(record);
    }
 };

 const dataSource = data.map((item, index) => ({
    key: index,
    stt: index + 1,
    ...item,
 }));

 

 return (
    <Table
      dataSource={dataSource}
      columns={generateColumns()}
      pagination={false}
      expandable={{
      expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
      expandedRowRender: (record) =>
 expandedRowKey === record.key ? (
    <Form
      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
      form={form}
      layout="vertical"
    >
      {record.student.scores.map((score, index) => (
        <Form.Item key={index} name={score.title} label={`Q${score.title}`}>
          <Input />
        </Form.Item>
      ))}
      <Form.Item name="note" label="Note">
        <Input />
      </Form.Item>
      <Button type="primary" onClick={handleSave}>
        Save
      </Button>
    </Form>
 ) : null

      }}
      onExpand={(expanded, record) => {
        if (expanded) {
          toggleRow(record);
        } else {
          setExpandedRowKey(null);
        }
      }}
    />
 );
};

export default ExamTeamsScoreTable;
