// actionClass
import {
  SET_DATA_CLASS,
  UPDATE_CLASS,
} from "../constant/constantClass";
import { classServ } from "../../Services/classService";

export const setDataListClassSuccess = (successValue) => ({
    type: SET_DATA_CLASS,
    payload: successValue,
});
const initialState = {
  dataListClass: [],
  class: null,
};


export const setDataListClass = () => (dispatch) => {
  classServ
    .getDataClass()
    .then((res) => {
      dispatch(setDataListClassSuccess(res));
    })
    .catch((err) => {
      console.error("Error fetching classes:", err);
    });
};

export const addClass = (classData) => (dispatch) => {
  classServ
    .addClass(classData)
    .then((res) => {
      console.log("Class added successfully", res);
      dispatch(setDataListClass());
    })
    .catch((err) => {
      console.error("Failed to add class:", err);
    });
};

export const editClass = (id, classData) => (dispatch) => {
  classServ
    .editClass(id, classData)
    .then((res) => {
      console.log(`Class with ID ${id} edited successfully`, res);
      dispatch(setDataListClass());
    })
    .catch((err) => {
      console.error(`Failed to edit class with ID ${id}:`, err);
    });
};

export const deleteClass = (id) => (dispatch) => {
  classServ
    .deleteClass(id)
    .then((res) => {
      console.log(`Class with ID ${id} deleted successfully`, res);
      dispatch(setDataListClass());
    })
    .catch((err) => {
      console.error(`Failed to delete class with ID ${id}:`, err);
    });
};

export const searchClasses = (values) => (dispatch) => {
  if (!values) {
    classServ
      .getDataClass()
      .then((res) => {
        dispatch(setDataListClassSuccess(res));
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    classServ
      .searchClass(values)
      .then((res) => {
        dispatch(setDataListClassSuccess(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const updateClass = (classData) => ({
  type: UPDATE_CLASS,
  payload: classData,
});

export const importClass = (classData) => {
  return (dispatch) => {
    classServ
      .importClass(classData) // Use the correct service method
      .then((response) => {
        console.log("Import successful", response);
        dispatch(setDataListClass()); // Fetch the updated list
      })
      .catch((error) => {
        console.error("Import failed", error);
      });
  };
};

export const setDataListExamteams = () => (dispatch) => {
  classServ
      .getDataExamteams()
      .then((res) => {
          // Assuming res is the array of data you need
          dispatch(setDataListClassSuccess(res)); // Use res.data if the data is nested within a 'data' key
      })
      .catch((err) => {
          console.error("Error fetching exam teams:", err);
      });
};



export const searchExamteams = (values) => {
  return (dispatch) => {
    if (!values) {
      classServ
        .getDataExamteams()
        .then((res) => {
          dispatch(setDataListClassSuccess(res.data)); // Và cập nhật tại đây
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      classServ
        .searchCourse(values)
        .then((res) => {
          dispatch(setDataListClassSuccess(res.data)); // Cũng cập nhật tại đây nếu cần
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

// redux/actions.js
export const updateExamTeams = (data) => {
  return {
    type: "UPDATE_CLASS",
    payload: data,
  };
};

export const importExamTeams = (examTeamsData) => {
  return (dispatch) => {
    classServ
      .importExamTeams(examTeamsData) // Use the correct service method
      .then((response) => {
        console.log("Import successful", response);
        dispatch(setDataListExamteams()); // Fetch the updated list
      })
      .catch((error) => {
        console.error("Import failed", error);
      });
  };
};

// Trong actionExamteams
export const importExamTeamsScores = (id, scoresData) => {
  return async (dispatch) => {
    try {
      const response = await classServ.importExamTeamsScore(id, { examScore: scoresData });
      console.log("Scores import successful", response);
      dispatch(setDataListExamteams()); // Cập nhật danh sách sau khi import nếu cần
    } catch (error) {
      console.error("Scores import failed", error);
    }
  };
};


// Trong actions.js hoặc actionExamteams.js
export const updateExamTeamsScores = (scoresData) => ({
  type: UPDATE_CLASS, // Đảm bảo type này phù hợp với reducer
  payload: scoresData,
});


