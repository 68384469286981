import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { classServ } from "../../Services/classService";
import * as XLSX from "xlsx";

const ClassEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [importedStudents, setImportedStudents] = useState([]); // State to hold imported students


  useEffect(() => {
    async function fetchData() {
       try {
         const data = await classServ.getClass(id);
         if (data) {
           form.setFieldsValue({
            
              id: data.id,

               courseID: data.courseID,
               courseName: data.courseName,
               courseGroup: data.courseGroup,
               numberOfStudent: data.numberOfStudent,
               lecturer: data.lecturer,
               semester: data.semester, // Include semester
               schoolyear: data.schoolyear, // Include schoolyear
             
             students: data.students, // Include students
           });
         }
       } catch (err) {
         message.error("An error occurred while fetching course data.");
         console.error(err);
       }
    }
    fetchData();
   }, [id, form]);
   

   const onFinishMainForm = async (values) => {
    try {
       // Construct the updated class object with the correct structure
       const updatedClass = {
         ...values, // Spread the class values first
         id: values.id, // Then add the id
         semester: values.semester, // Include semester
         schoolyear:values.schoolyear,
         students: importedStudents.length > 0 ? importedStudents : values.class.students, // Use imported students if available

        };
   
       // Ensure you're using the correct ID for the class
       await classServ.editClass(updatedClass.id, updatedClass);
       message.success("Class updated successfully");
       navigate("/admin/class");
    } catch (err) {
       message.error("Failed to update class.");
       console.error(err);
    }
   };
   
   
   

  const handleImportStudents = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
       const workbook = XLSX.read(e.target.result, { type: "binary" });
       const sheetName = workbook.SheetNames[0];
       const worksheet = workbook.Sheets[sheetName];
       const excelData = XLSX.utils.sheet_to_json(worksheet);
   
       const formattedData = excelData.map((item) => ({
         studentID: item["Student ID"],
         studentFirstName: item["Student First Name"],
         studentLastName: item["Student Last Name"],
       }));
   
       classServ
         .importStudents(id, formattedData)
         .then(() => {
          setImportedStudents(formattedData); // Set imported students in state
           message.success("Student list imported successfully");
         })
         .catch((error) => {
           message.error("Failed to import student list: " + error.message);
         });
    };
    reader.readAsBinaryString(file);
   };
   

  const beforeUpload = (file) => {
    const isExcel =
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      message.error(`${file.name} is not an Excel file`);
    }
    return isExcel;
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinishMainForm}
        layout="vertical"
        name="classForm"
        initialValues={{
            courseID: "",
            courseName: "",
            courseGroup: "",
            lecturer: "",
            semester: "",
            schoolyear: "",
            students: [],
        }}
      >
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Class
          </Button>
        </Form.Item>

        <Form.Item name="id" label="id" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          name={"courseID"}
          label="Course ID"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"courseName"}
          label="Course Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"courseGroup"}
          label="Course Group"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={ "numberOfStudent"}
          label="Number of Student"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"lecturer"}
          label="Lecturer"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        {/* Import Students */}
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={(e) => handleImportStudents(e.target.files[0])}
        />
      </Form>
    </div>
  );
};

export default ClassEdit;