import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Modal,
  Row,
  Col,
  Alert,
  InputNumber,
  Table,
  Select,
} from "antd";
import { examteamsServ } from "../../Services/examteamsService";
import { courseServ } from "../../Services/courseService";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./examTeam.css";

const { Option } = Select;

const ExamTeamsEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [examStructureForm] = Form.useForm();
  const [examStructureModalVisible, setExamStructureModalVisible] =
    useState(false);
  const [rubricModalVisible, setRubricModalVisible] = useState(false);
  
  const [examStructure, setExamStructure] = useState({
    NoofQuestion: 0,
    TotalScore: 0,
    tieuchi: [],
  });
  const [courseCLOs, setCourseCLOs] = useState([]);
  const [warning, setWarning] = useState(false);
  const [rubrics, setRubrics] = useState([
    {
      id: 0,
      criteria: [],
    },
  ]);

  const [criteria, setCriteria] = useState([]);
  const [criteriaContent, setCriteriaContent] = useState([]);

  useEffect(() => {
    // Fetch exam team data and course CLOs based on the exam team ID
    fetchExamTeamAndCLOs(id);
  },[id]);

  const fetchExamTeamAndCLOs = async (examTeamId) => {
    try {
      // Fetch exam team data
      const examTeam = await examteamsServ.getExamteams(examTeamId);
      // Extract course ID from exam team data
      const courseId = examTeam.courseID;
      // Fetch course data based on the courseId
      const courseCLOs = await courseServ.getCLOsByCourseId(courseId); // Updated function call
      // Update state with course CLOs
      setCourseCLOs(courseCLOs);
      // Update form fields with exam team data
      form.setFieldsValue(examTeam);
      setExamStructure(examTeam.examStructure || { NoofQuestion: 0, tieuchi: [] });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  
  



  useEffect(() => {
    examteamsServ.getExamteams(id).then((data) => {
      form.setFieldsValue(data);
      setExamStructure(data.examStructure || { NoofQuestion: 0, tieuchi: [] });
    });
  }, [id, form]);

  useEffect(() => {
    if (examStructure && examStructure.tieuchi) {
      const maxScores = examStructure.tieuchi.map((item) => item.maxScore);
      const totalScore = examStructure.TotalScore;
      const sumOfMaxScores = maxScores.reduce((sum, score) => sum + score, 0);
      setWarning(sumOfMaxScores > totalScore);
    }
  }, [examStructure]);
  

  
  const onExamStructureFinish = async (values) => {
    try {
      await examteamsServ.editExamteams(id, {
        examStructure: values.examStructure,
      });
      message.success("Exam structure updated successfully");
      setExamStructureModalVisible(false);
    } catch (err) {
      message.error("Failed to update exam structure");
      console.error(err);
    }
  };

  const renderTieuchiInputs = () => {
    let inputs = [];
    for (let i = 0; i < examStructure.NoofQuestion; i++) {
      inputs.push(
        <Row key={`tieuchi-${i}`} gutter={16}>
          <Col span={6}>
            <Form.Item
              name={["examStructure", "tieuchi", i, "id"]}
              label={`Câu/Tiêu chí ${i + 1} (CLOs)`}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={["examStructure", "tieuchi", i, "maxScore"]}
              label={`Max Score`}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={["examStructure", "tieuchi", i, "note"]}
              label={`Select CLOs`}
            >
   <Select
              mode="multiple"
              placeholder="Type or select CLOs"
              style={{ width: '100%' }}
              dropdownRender={(menu) => (
                <div className="custom-dropdown">
                  {menu}
                </div>
              )}
            >
              {courseCLOs && courseCLOs.length > 0 && courseCLOs.flatMap(clo => {
                const cloKey = Object.keys(clo).find(key => key.startsWith('clo') && key !== 'cloNote');
                const cloValue = clo[cloKey];
                const cloNote = clo.cloNote;
                return cloKey && cloValue ? (
                  <Option key={cloValue} value={cloValue}>
                    <div className="custom-option">{`${cloValue} - ${cloNote}`}</div>
                  </Option>
                ) : null;
              })}
            </Select>


            </Form.Item>
          </Col>
        </Row>
      );
    }
    return inputs;
  };

  const onFinishMainForm = async (values) => {
    try {
      await examteamsServ.editExamteams(id, values);
      message.success("Exam team updated successfully");
      navigate("/admin/examteams");
    } catch (error) {
      message.error("Failed to update exam team");
      console.error(error);
    }
  };

  const addRubric = () => {
    const newRubric = {
      id: rubrics.length,
      criteria: [],
    };
    setRubrics([...rubrics, newRubric]);
  };

  const addCriterion = () => {
    const newCriterion = {
      id: criteria.length,
      name: "",
      content: "",
      lowerScore: 0,
      upperScore: 0,
    };
    setCriteria([...criteria, newCriterion]);
  };

  const removeRubric = (rubricIndex) => {
    const filteredRubrics = rubrics.filter((_, index) => index !== rubricIndex);
    setRubrics(filteredRubrics);
  };

  const removeCriterion = (criterionId) => {
    const filteredCriteria = criteria.filter(
      (criterion) => criterion.id !== criterionId
    );
    setCriteria(filteredCriteria);
  };

  const saveRubric = async () => {
    const rubricPayload = rubrics.map((rubric) => ({
      NoofCriteria: rubric.criteria.length.toString(),
      criteria: rubric.criteria.map((criterion) => ({
        id: criterion.id,
        name: criterion.name,
        lowerScore: criterion.lowerScore.toString(),
        upperScore: criterion.upperScore.toString(),
        content: criterion.content,
      })),
    }));

    try {
      // Now we call the API, ensuring to include the entire form data along with the rubric.
      const formData = form.getFieldsValue();
      const updatedData = {
        ...formData, // Spread the existing form data
        rubric: rubricPayload, // Add the rubric payload
      };

      // Make the API call with the assembled data
      await examteamsServ.editExamteams(id, updatedData);
      message.success("Rubric saved successfully");
      setRubricModalVisible(false);
    } catch (error) {
      message.error("Failed to save rubric data");
      console.error(error);
    }
  };

  const updateCriterion = (criterionId, updatedCriterion) => {
    const updatedCriteria = criteria.map((criterion) => {
      if (criterion.id === criterionId) {
        return { ...criterion, ...updatedCriterion };
      }
      return criterion;
    });
    setCriteria(updatedCriteria);
  };

  const updateCriteriaContent = (rubricId, criterionId, content) => {
    const updatedContent = criteriaContent.map((item) => {
      if (item.rubricId === rubricId && item.criteriaId === criterionId) {
        return { ...item, content: content };
      }
      return item;
    });
    setCriteriaContent(updatedContent);
  };
  

  
  const renderRubricCriteriaMatrix = () => {
    return (
      <Table
        dataSource={rubrics}
        columns={[
          {
            title: "Rubric/Criteria",
            dataIndex: "id",
            key: "id",
            render: (text, record) => (
              <div>
                {`Rubric ${record.id + 1}`}
                <Button
                  type="link"
                  danger
                  onClick={() => removeRubric(record.id)}
                  style={{ float: "right" }}
                  icon={<CloseCircleOutlined />}
                />
              </div>
            ),
          },
          
          ...criteria.map((criterion) => ({
            title: (
              <div>
                <Button
                  type="link"
                  danger
                  onClick={() => removeCriterion(criterion.id)}
                  style={{ float: "right" }}
                  icon={<CloseCircleOutlined />}
                />
                <Form.Item label="Criteria Name">
                  <Input
                    placeholder="Criteria Name"
                    value={criterion.name}
                    onChange={(e) =>
                      updateCriterion(criterion.id, {
                        ...criterion,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="Score Range">
                  <Row gutter={8}>
                    <Col span={10}>
                      <Form.Item
                        name={[
                          "examStructure",
                          "tieuchi",
                          criterion.id,
                          "lowerScore",
                        ]}
                      >
                        <InputNumber
                          placeholder="00"
                          value={criterion.lowerScore}
                          onChange={(value) =>
                            updateCriterion(criterion.id, {
                              ...criterion,
                              lowerScore: value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} style={{ textAlign: "center" }}>
                      to
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name={[
                          "examStructure",
                          "tieuchi",
                          criterion.id,
                          "upperScore",
                        ]}
                      >
                        <InputNumber
                          placeholder="00"
                          value={criterion.upperScore}
                          onChange={(value) =>
                            updateCriterion(criterion.id, {
                              ...criterion,
                              upperScore: value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                
              </div>
             
            ),
            dataIndex: `criterion${criterion}`,
            key: `criterion${criterion}`,
            render: (text, record) => (
              <div>
                {/* Render the rubric's score for this criterion */}
                <Input placeholder="Content"
                 value={record.content}
                 onChange={(e) =>
                  updateCriteriaContent(
                    record.id,
                    e.target.value
                  )
                }
                 />
              </div>),
          })),
        ]}
      />
    );
  };
 
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinishMainForm}
        layout="vertical"
        name="editExamTeamForm"
      >
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Exam Team
          </Button>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="id" label="id" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="courseID"
              label="Course ID"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="courseName"
              label="Course Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="courseGroup"
              label="Course Group"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="examTeams"
              label="Exam Teams"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="numberOfStudent"
              label="Number of Student"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="examDate"
              label="Exam Date"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="examTime"
              label="Exam Time"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="examRoom"
              label="Exam Room"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="examType"
              label="Exam Type"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lecturer"
              label="Lecturer"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Button
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            borderColor: "#4CAF50",
          }}
          type="primary"
          onClick={() => setExamStructureModalVisible(true)}
        >
          Edit Exam Structure
        </Button>
        <Button
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            borderColor: "#4CAF50",
            marginLeft: 10,
          }}
          type="primary"
          onClick={() => setRubricModalVisible(true)}
        >
          Set Rubric
        </Button>
      </Form>

      <Modal
        title="Set Rubric"
        visible={rubricModalVisible}
        onCancel={() => setRubricModalVisible(false)}
        footer={null}
        width={"auto"} // Set width to auto for dynamic adjustment
        style={{ minWidth: "500px" }} // Set a minimum width to prevent the modal from becoming too narrow
      >
        {renderRubricCriteriaMatrix()}

        <Button onClick={addRubric}>Add Rubric</Button>
        <Button onClick={addCriterion}>Add Criterion</Button>
        <Button type="primary" onClick={saveRubric}>
          Save Rubric
        </Button>
      </Modal>

      <Modal
        title="Edit Exam Structure"
        visible={examStructureModalVisible}
        onCancel={() => setExamStructureModalVisible(false)}
        footer={null}
        width={800} // Adjust the width value as needed
      >
        <Form
          form={examStructureForm}
          onFinish={onExamStructureFinish}
          layout="vertical"
          name="examStructureForm"
          initialValues={{ examStructure: examStructure }}
        >
          <Form.Item
            name={["examStructure", "NoofQuestion"]}
            label="Number of Questions"
          >
            <InputNumber
              min={0}
              max={20}
              onChange={(value) =>
                setExamStructure({ ...examStructure, NoofQuestion: value })
              }
            />
          </Form.Item>
          <Form.Item name={["examStructure", "TotalScore"]} label="Total Score">
            <InputNumber
              min={0}
              max={110}
              onChange={(value) =>
                setExamStructure({ ...examStructure, TotalScore: value })
              }
            />
          </Form.Item>
        

          {renderTieuchiInputs()}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Exam Structure
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {warning && (
        <Alert
          message="Warning"
          description="The sum of all max scores can not be greater than the total score."
          type="warning"
          showIcon
          closable
        />
      )}
    </div>
  );
};

export default ExamTeamsEdit;